import f1cover from "../../../Images/f1front.png";
import f11 from "../../../Images/f1side.png";
import f12 from "../../../Images/f1bag.png";
import f13 from "../../../Images/f1perspective.png";
import freecover from "../../../Images/freefront.png";
import free1 from "../../../Images/freeside.png";
import free2 from "../../../Images/freebag.png";
import free3 from "../../../Images/free3D.png";
import r2cover from "../../../Images/r2FRONT.png";
import r21 from "../../../Images/r2SIDE-.png";
import r22 from "../../../Images/rallydetailbag.png";
import r23 from "../../../Images/r2perspective.png";
import r3cover from "../../../Images/r3front.png";
import r31 from "../../../Images/race3side.png";
import r32 from "../../../Images/race1bag.png";
import r33 from "../../../Images/race3perspectiver.png";
import invcover from "../../../Images/invfront.png";
import inv1 from "../../../Images/invside.png";
import inv2 from "../../../Images/inovabag.png";
import inv3 from "../../../Images/inv3d.png";
import rpbluecover from "../../../Images/rpblue-front.png";
import rp1 from "../../../Images/r2plusside-blue.png";
import rp2 from "../../../Images/rallyplusbag.png";
import rp3 from "../../../Images/r2plusperspectice-blue.png";
import zycover from "../../../Images/zyfront.png";
import zy1 from "../../../Images/zside.png";
import zy2 from "../../../Images/z3d.png";
import zy3 from "../../../Images/zylonbag.png";
import sense2cover from "../../../Images/s2front.png";
import sense21 from "../../../Images/s2side.png";
import sense22 from "../../../Images/sense2pack.png";
import sense23 from "../../../Images/s2perspective.png";
import sense3cover from "../../../Images/s3front.png";
import sense31 from "../../../Images/s3side.png";
import sense32 from "../../../Images/sense3bag.jpg";
import sense33 from "../../../Images/s3perspectice.png";
import solocover from "../../../Images/soloFRONT.png";
import solo1 from "../../../Images/soloSIDE.png";
import solo2 from "../../../Images/soloPERSPECTIVE.png";
import solo3 from "../../../Images/soloBACK.png";
import solo4 from "../../../Images/solo1bag.png";
import freelogo from "../../../Images/logofreedom.png";
import invlogo from "../../../Images/logoinova6.png";
import sense3logo from "../../../Images/logosense3.png";
import sense2logo from "../../../Images/logosense2.png";
import f1logo from "../../../Images/logoformula1.png";
import race3logo from "../../../Images/logorace3.png";
import rallyplogo from "../../../Images/logoRally2plus.png";
import rally2logo from "../../../Images/logorally2.png";
import zylonelogo from "../../../Images/logozylon6.png";
import solologo from "../../../Images/logosolo1.png"
let Data = [
  {
    id: "2",
    title: "FREEDOM",
    logotype: freelogo,
    coverimg: freecover,
    bag: free2,
    Image: [freecover, free3, free1, free2],
    categoryId: "A",
    desc: "The Freedom Razor pack includes three razors, each featuring a 3-blade German head designed for a close and smooth shave. The non-slip, ergonomic handle ensures a secure and comfortable grip, while the pivoting head provides maximum skin contact for a precise shave. Enhanced with a lubricant strip containing aloe vera extract and vitamin E, these razors offer excellent skin protection and soothing care, making each shave both effective and gentle.",
    Feature: [
      "3-Blade German Head",
      "Ergonomic Non-Slip Handle",
      "Pivoting Head",
      "Lubricant Strip, contains aloe vera and vitamin E",
      "Effective and Gentle Shave",
    ],
  },
  {
    id: "1",
    title: "INOVA 6",
    logotype: invlogo,
    coverimg: invcover,
    bag: inv2,
    Image: [invcover, inv3, inv1, inv2],
    categoryId: "A",
    desc: "The Inova 6 Razor features a single high-performance razor with a 6-edged German blade coated with an exceptionally thin nano layer for a precise and smooth shave. Its non-slip, ergonomic handle provides superior control and comfort. Designed for meticulous grooming, the pivoting head ensures maximum contact with the skin, while the lubricant strip, infused with aloe vera extract and vitamin E, offers added protection and soothing benefits for a comfortable shaving experience.",
    Feature: [
      "6-Edged German Blade",
      "Ergonomic Non-Slip Handle",
      "Pivoting Head",
      "Lubricant Strip, Infused with aloe vera and vitamin E",
      "High-Performance Design",
    ],
  },
  {
    id: "3",
    title: "SENSE 3",
    logotype: sense3logo,
    coverimg: sense3cover,
    bag: sense32,
    Image: [sense3cover, sense33, sense31, sense32],
    categoryId: "A",
    desc: "The Sense 3 Razor pack includes three razors, each featuring a 3-blade German head for an exceptionally close and smooth shave. With its non-slip, ergonomic handle, these razors provide a comfortable and secure grip. The pivoting head ensures optimal skin contact for precise shaving, while the lubricant strip, enriched with aloe vera extract and vitamin E, offers additional skin protection and soothing care, making every shave gentle and effective.",
    Feature: [
      "3-Blade German Head",
      "Ergonomic Non-Slip Handle",
      "Pivoting Head",
      "Lubricant Strip, Infused with aloe vera and vitamin E",
      "Gentle and Effective Shaving",
    ],
  },
  {
    id: "4",
    title: "SENSE 2",
    logotype: sense2logo,
    coverimg: sense2cover,
    bag: sense22,
    Image: [sense2cover, sense23, sense21, sense22],
    categoryId: "A",
    desc: "The Sense 2 Razor pack includes four razors, each with a 2-blade German head designed for a close and efficient shave. Featuring an ergonomic handle for enhanced comfort and control, these razors are ideal for both precision and skin protection. The lubricant strip, enriched with aloe vera extract and vitamin E, provides soothing care and additional protection, ensuring a smooth and gentle shaving experience.",
    Feature: [
      "2-Blade German Head",
      "Ergonomic Handle",
      "Lubricant Strip, enriched with aloe vera and vitamin E",
      "Smooth and Gentle Shaving",
    ],
  },

  {
    id: "6",
    title: "FORMUL 1",
    logotype: f1logo,
    coverimg: f1cover,
    bag: f12,
    Image: [f1cover, f13, f11, f12],
    categoryId: "B",
    desc: "The Formula 1 Razor pack includes three razors, each designed with a 3-blade German head for a close and effective shave. Featuring an ergonomic, non-slip handle, these razors provide excellent control and comfort. The pivoting head ensures maximum contact with the skin, allowing for a smooth and precise shave every time. Additionally, the lubricant strip, enriched with aloe vera extract and vitamin E, helps protect and soothe the skin throughout the shaving process.",
    Feature: [
      "Triple German Blades",
      "Ergonomic Non-Slip Handle",
      "Flexible Head",
      "Lubricating Strip, enriched with aloe vera and vitamin E",
      "Smooth Shaving Experience",
    ],
  },
  {
    id: "5",
    title: "ZYLONE",
    logotype: zylonelogo,
    coverimg: zycover,
    bag: zy3,
    Image: [zycover, zy2, zy1, zy3],
    categoryId: "B",
    desc: "The Zylon 6 Razor is equipped with a 6-edged German blade featuring ultra-thin nano coating for an exceptionally smooth shave. Its ergonomic, non-slip handle provides superior control, while the pivoting head ensures maximum skin contact for precise shaving. Designed for both precision and comfort, the razor includes a lubricant strip infused with aloe vera extract and vitamin E, offering skin protection and soothing benefits during every shave.",
    Feature: [
      "6-Edged German Blade",
      "Ergonomic Non-Slip Handle",
      "Pivoting Head",
      "Lubricant Strip, Infused with aloe vera and vitamin E",
      "Designed for Precision and Comfort",
    ],
  },
  {
    id: "7",
    title: "RACE 3",
    logotype: race3logo,
    coverimg: r3cover,
    bag: r32,
    Image: [r3cover, r33, r31, r32],
    categoryId: "B",
    desc: "The Race 3 Razor pack includes three high-performance razors, each featuring a 3-blade German head for a precise and close shave. The non-slip, ergonomic handle ensures a secure and comfortable grip, while the pivoting head adapts to the contours of your face for optimal contact with the skin. With a lubricant strip enriched with aloe vera extract and vitamin E, these razors provide added protection and soothing care for your skin, making shaving both efficient and gentle.",
    Feature: [
      "3-Blade German Head",
      "Ergonomic Non-Slip Handle",
      "Pivoting Head",
      "Lubricant Strip, Infused with aloe vera and vitamin E",
      "High-Performance Design",
    ],
  },
  {
    id: "8",
    title: "RALLY 2 PLUS",
    logotype: rallyplogo,
    coverimg: rpbluecover,
    bag: rp2,
    Image: [rpbluecover, rp3, rp1, rp2],
    categoryId: "B",
    desc: "The Rally Plus 2 Razor pack includes four razors, each featuring a 2-blade German head designed for a close and comfortable shave. With an ergonomic handle for enhanced grip and control, these razors ensure an effortless shaving experience. The lubricant strip, enriched with aloe vera extract and vitamin E, provides added protection and care for the skin, making shaving both smooth and soothing.",
    Feature: [
      "2-Blade German Head",
      "Ergonomic Handle",
      "Pivoting Head",
      "Lubricant Strip, Infused with aloe vera and vitamin E",
      "Smooth and Soothing Shaving ",
    ],
  },
  {
    id: "9",
    title: "RALLY 2",
    logotype: rally2logo,
    coverimg: r2cover,
    bag: free2,
    Image: [r2cover, r23, r21, r22],
    categoryId: "B",
    desc: "The Rally 2 Razor pack includes four razors, each equipped with a 2-blade head for a close and precise shave. Designed with an ergonomic handle, these razors offer excellent comfort and control. The lubricant strip, enriched with aloe vera extract and vitamin E, provides added skin protection and soothing care, ensuring a smooth and gentle shaving experience.",
    Feature: [
      "2-Blade Head",
      "Ergonomic Handle",
      "Lubricant Strip, enriched with aloe vera and vitamin E ",
      "Smooth and Gentle Shaving",
    ],
  },

  {
    id: "10",
    title: "SOLO 1",
    logotype: solologo,
    coverimg: solocover,
    Image: [solo3, solo2, solo1, solo4],
    categoryId: "B",
    desc: "The SOLO1 Razor is designed for perfect dry body shaving. It features a sleek head with an ultra-thin cartridge, ideal for precise trimming around contours and protrusions. Its compact comb effortlessly lifts and separates hair, preventing clogging, nicks, and cuts. The ergonomic handle provides a comfortable grip, designed for ease of use with a shorter handle for body grooming. The razor is suitable for both men and women, ensuring effective and gentle body hair removal with added comfort and precision",
    Feature: [
      "Sleek Head",
      "Compact Comb",
      "Ergonomic Handle",
      "Perfect Body Grooming for Men & Women",
    ],
  },
  {
    id: "10",
    title: "SOLO 1",
    logotype: solologo,
    coverimg: solocover,
    Image: [solo3, solo2, solo1, solo4],
    categoryId: "A",
    desc: "The SOLO1 Razor is designed for perfect dry body shaving. It features a sleek head with an ultra-thin cartridge, ideal for precise trimming around contours and protrusions. Its compact comb effortlessly lifts and separates hair, preventing clogging, nicks, and cuts. The ergonomic handle provides a comfortable grip, designed for ease of use with a shorter handle for body grooming. The razor is suitable for both men and women, ensuring effective and gentle body hair removal with added comfort and precision",
    Feature: [
      "Sleek Head",
      "Compact Comb",
      "Ergonomic Handle",
      "Perfect Body Grooming for Men & Women",
    ],
  },
];
export default Data;
